<template>
  <div :class="['row', dir == 'rtl' ? 'reverse' : '', 'row-section']">
    <div class="col-lg-6 my-4 my-lg-0 image" v-if="image">
      <img :src="image" alt="" />
    </div>
    <div class="col-lg-6 details">
      <h4 class="sec-title mb-5" v-if="sectionTitle">
        {{ sectionTitle }}
      </h4>
      <p v-html="sectionText" v-if="sectionText"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["image", "sectionTitle", "sectionText", "dir"],
};
</script>

<style>
</style>