<template>
  <div class="solutions-page">
    <page-header
      :cover="solution.img"
      :title="solution.title"
      :desc="solution.desc"
    />
    <div class="container">
      <div class="row boxs my-4 mb-5 px-3">
        <How-it-works-card
          v-for="(cardData, index) in cardsData"
          :dataCard="cardData"
          :key="index"
          :back="false"
        />
      </div>
      <row-section
        :image="require('../assets/images/solution-1.jpg')"
        sectionText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet,<p></> consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet,<p></> consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet,<p></> consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. "
        sectionTitle="What We Introduce"
        dir="rtl"
      />

      <row-section
        :image="require('../assets/images/solution-3.jpg')"
        sectionText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet,<p></> consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet,<p></> consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet,<p></> consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              eget sagittis enim. "
        :sectionTitle="false"
        dir="ltr"
      />
      <div class="header text-center">
        <div class="sec-desc">Our Use Cases</div>
        <div class="sec-title">Our Case Projects</div>
      </div>
      <div class="projects-section">
        <project-card
          :project="proj"
          v-for="(proj, projInd) in projects"
          :key="projInd"
          @openProject="toggelModal"
        />
      </div>
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <modal
        :project="modalData"
        :link="link"
        :btnName="btnName"
        v-if="activeModal"
        @closeModal="toggelModal"
      />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HowItWorksCard from "../components/Layout/HowItWorksCard.vue";
import Modal from "../components/Layout/Modal.vue";
import ProjectCard from "../components/Layout/projectCard.vue";
import RowSection from "../components/Layout/RowSection.vue";

import PageHeader from "../components/misc/PageHeader.vue";
export default {
  components: { PageHeader, HowItWorksCard, RowSection, ProjectCard, Modal },
  data() {
    return {
      solution: {},
      modalData: null,
      link: null,
      btnName: null,
      activeModal: false,
      projects: [
        {
          cover: require("../assets/images/proj-1.png"),
          title: "Sample Project",
          desc: "Duis sit amet viverra pharetra ornare ut aliquet metus congue. Donec morbi faucibus eget donec.",
          imgs: [
            require("../assets/images/iphone.png"),
            require("../assets/images/iphones.png"),
            require("../assets/images/iphonex.png"),
          ],
          id: 1,
          tabId: 2,
        },
        {
          cover: require("../assets/images/proj-2.png"),
          title: "Sample Project 2",
          desc: "Duis sit amet viverra pharetra ornare ut aliquet metus congue. Donec morbi faucibus eget donec.",
          imgs: [
            require("../assets/images/iphone.png"),
            require("../assets/images/iphones.png"),
            require("../assets/images/iphonex.png"),
          ],
          id: 1,
          tabId: 2,
        },
        {
          cover: require("../assets/images/proj-3.png"),
          title: "Sample Project 3",
          desc: "Duis sit amet viverra pharetra ornare ut aliquet metus congue. Donec morbi faucibus eget donec.",
          imgs: [
            require("../assets/images/iphone.png"),
            require("../assets/images/iphones.png"),
            require("../assets/images/iphonex.png"),
          ],
          id: 1,
          tabId: 3,
        },
        {
          cover: require("../assets/images/proj-4.png"),
          title: "Sample Project 4",
          desc: "Duis sit amet viverra pharetra ornare ut aliquet metus congue. Donec morbi faucibus eget donec.",
          imgs: [
            require("../assets/images/iphone.png"),
            require("../assets/images/iphones.png"),
            require("../assets/images/iphonex.png"),
          ],
          id: 1,
          tabId: 4,
        },
        {
          cover: require("../assets/images/proj-5.png"),
          title: "Sample Project 5",
          desc: "Duis sit amet viverra pharetra ornare ut aliquet metus congue. Donec morbi faucibus eget donec.",
          imgs: [
            require("../assets/images/iphone.png"),
            require("../assets/images/iphones.png"),
            require("../assets/images/iphonex.png"),
          ],
          id: 1,
          tabId: 5,
        },
        {
          cover: require("../assets/images/proj-6.png"),
          title: "Sample Project 6",
          desc: "Duis sit amet viverra pharetra ornare ut aliquet metus congue. Donec morbi faucibus eget donec.",
          imgs: [
            require("../assets/images/iphone.png"),
            require("../assets/images/iphones.png"),
            require("../assets/images/iphonex.png"),
          ],
          id: 1,
          tabId: 6,
        },
      ],
      cardsData: [
        {
          img: require("../assets/images/shield.png"),
          title: this.$t("safeAndSecured"),
          description: this.$t("safeAndSecuredDesc"),
        },
        {
          img: require("../assets/images/rating.png"),
          title: this.$t("highlyExpertTeam"),
          description: this.$t("highlyExpertTeamDesc"),
        },
        {
          img: require("../assets/images/shield.png"),
          title: this.$t("ourMagicalFormula"),
          description: this.$t("ourMagicalFormulaDesc"),
        },
      ],
    };
  },
  mounted() {
      this.solution = this.solutions.find(sol => sol.id == this.$route.params.id);
  },
  computed:{
    ...mapGetters(['solutions'])
  },
  methods: {
    toggelModal(proj) {
      this.activeModal = !this.activeModal;
      if (proj) {
        this.modalData = proj;
        this.btnName = "See Use Cases";
        this.link = {
          name: "ProjectDetails",
          params: { id: proj.id },
        };
      }
    },
  },
};
</script>

<style>
</style>